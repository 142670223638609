'use client'

import Link from 'next/link'
import {c} from 'utils/className'
import {useActivePath} from 'contexts/ActivePathContext'
import styles from './PrimaryLink.module.scss'

type Props = {
  label: string
  href: string
}

const PrimaryLink = ({label, href}: Props) => {
  const activePath = useActivePath()
  const isActive = activePath?.startsWith(href)

  return (
    <Link className={c(styles.item, isActive && styles.active)} href={href}>
      {label}
    </Link>
  )
}

export default PrimaryLink
