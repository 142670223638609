'use client'

import Dialog from 'components/modals/Dialog/Dialog'
import {useDialog} from 'contexts/DialogContext'

const GlobalDialog = () => {
  const {isVisible, title, body, buttons, close} = useDialog()

  if (!isVisible) {
    return null
  }

  return (
    <Dialog title={title} buttons={buttons} onClose={close}>
      {body}
    </Dialog>
  )
}

export default GlobalDialog
