import {useEffect} from 'react'

const useDisableScroll = () =>
  useEffect(() => {
    document.body.style.height = '100%'
    document.body.style.width = '100%'
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.height = 'unset'
      document.body.style.width = 'unset'
      document.body.style.overflow = 'unset'
    }
  })

export default useDisableScroll
