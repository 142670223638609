'use client'

import {
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider
} from '@tanstack/react-query'
import {ReactNode} from 'react'

const queryClient = new QueryClient()

type Props = {
  children: ReactNode
}

const QueryClientProvider = ({children}: Props) => {
  return <TanstackQueryClientProvider client={queryClient}>{children}</TanstackQueryClientProvider>
}

export default QueryClientProvider
