'use client'

import React, {ReactNode, createContext, useContext, useMemo, useState} from 'react'
import useDevice from 'hooks/useDevice'

type Context = {
  isCameraEnabled: boolean
  toggleCamera: () => void
}

const CameraSettingsContext = createContext<Context | undefined>(undefined)

type ProviderProps = {
  children: ReactNode
}

export const CameraSettingsProvider = ({children}: ProviderProps) => {
  const device = useDevice()
  const [isCameraEnabled, setIsCameraEnabled] = useState(false)

  const value = useMemo<Context>(
    () => ({
      // Camera is always enabled on touch device:
      isCameraEnabled: device === 'touch' ? true : isCameraEnabled,
      toggleCamera: () => setIsCameraEnabled((cur) => !cur)
    }),
    [isCameraEnabled, device]
  )

  return <CameraSettingsContext.Provider value={value}>{children}</CameraSettingsContext.Provider>
}

export const useCameraSettings = () => {
  const context = useContext(CameraSettingsContext)
  if (context === undefined) {
    throw new Error('useCameraSettings must be used within a CameraSettingsProvider')
  }

  return context
}
