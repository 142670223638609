'use client'

import React, {ReactNode, RefObject, createContext, useContext} from 'react'

type ViewportRef = RefObject<HTMLDivElement>

const ViewportRefContext = createContext<ViewportRef | undefined>(undefined)

type ProviderProps = {
  viewportRef: ViewportRef
  children: ReactNode
}

export const ViewportRefProvider = ({viewportRef, children}: ProviderProps) => {
  return <ViewportRefContext.Provider value={viewportRef}>{children}</ViewportRefContext.Provider>
}

export const useViewportRef = () => {
  const context = useContext(ViewportRefContext)
  if (context === undefined) {
    throw new Error('useViewportRef must be used within a ViewportRefProvider')
  }

  return context
}
