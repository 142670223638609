import {RefObject, useEffect} from 'react'

export const useOnElementClick = (elementRef: RefObject<HTMLElement>, fn: () => void) => {
  useEffect(() => {
    if (!elementRef.current) {
      return
    }

    const handleClick = (e: MouseEvent) => {
      const hasClickedElement = e.target === elementRef.current
      if (hasClickedElement) {
        fn()
      }
    }
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [elementRef, fn])
}
