'use client'

import {ReactNode} from 'react'
import useDevice from 'hooks/useDevice'

type Props = {
  children: ReactNode
}

const TouchDeviceOnly = ({children}: Props) => {
  const device = useDevice()

  if (device === 'touch') {
    return <>{children}</>
  }
  return null
}

export default TouchDeviceOnly
