'use client'

import {ReactNode, useRef} from 'react'
import {ViewportRefProvider} from 'contexts/ViewportRefContext'
import Sidebar from 'components/sidebar/Sidebar/Sidebar'
import GlobalLoadingIndicator from 'components/loading/GlobalLoadingIndicator/GlobalLoadingIndicator'
import styles from './MouseLayout.module.scss'

type Props = {
  children: ReactNode
}

const MouseLayout = ({children}: Props) => {
  const viewportRef = useRef<HTMLDivElement>(null)

  return (
    <ViewportRefProvider viewportRef={viewportRef}>
      <div className={styles.viewport} ref={viewportRef}>
        <nav className={styles.sidebarContainer}>
          <Sidebar />
        </nav>
        <main className={styles.pageContainer}>{children}</main>
        <div className={styles.loadingIndicatorContainer}>
          <GlobalLoadingIndicator />
        </div>
      </div>
    </ViewportRefProvider>
  )
}

export default MouseLayout
