import Image from 'next/image'
import Link from 'next/link'
import {useMemo} from 'react'
import Scanner from 'public/scanner.png'
import {getT} from 'i18n/i18n'
import PrimaryLink from '../PrimaryLink/PrimaryLink'
import styles from './Sidebar.module.scss'

const t = getT('nav')

const PRIMARY_ITEMS = [
  {label: t('orders'), href: '/orders'},
  {label: t('customers'), href: '/customers'},
  {label: t('bags'), href: '/bags'},
  {label: t('return_locations'), href: '/return-locations'}
  // TODO: finish:
  // {label: t('activity'), href: '/activity'}
]

const SECONDARY_ITEMS = [{label: t('settings'), href: '/settings'}]

const Sidebar = () => {
  const primaryList = useMemo(
    () => (
      <ul>
        {PRIMARY_ITEMS.map(({label, href}) => (
          <li key={label}>
            <PrimaryLink label={label} href={href} />
          </li>
        ))}
      </ul>
    ),
    []
  )

  const secondaryList = useMemo(
    () => (
      <ul className={styles.secondaryList}>
        <li>
          <Link href={'/scanner'}>
            <div className={styles.scanner}>
              <Image className={styles.qrIcon} src={Scanner} alt={''} />
              {t('scanner')}
            </div>
          </Link>
        </li>
        {SECONDARY_ITEMS.map(({label, href}) => (
          <li key={label}>
            <Link href={href}>{label}</Link>
          </li>
        ))}
      </ul>
    ),
    []
  )

  return (
    <ul className={styles.container}>
      <li>{primaryList}</li>
      <li>{secondaryList}</li>
    </ul>
  )
}

export default Sidebar
