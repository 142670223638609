'use client'

import {ReactNode} from 'react'
import useDevice from 'hooks/useDevice'

type Props = {
  children: ReactNode
}

const MouseDeviceOnly = ({children}: Props) => {
  const device = useDevice()

  if (device === 'mouse') {
    return <>{children}</>
  }
  return null
}

export default MouseDeviceOnly
