'use client'

import {ReactNode, useRef} from 'react'
import FocusTrap from 'focus-trap-react'
import IconButton from 'components/IconButton/IconButton'
import {c} from 'utils/className'
import {useOnElementClick} from 'hooks/useOnElementClick'
import useOnKeyPress from 'hooks/useOnKeyPress'
import {getT} from 'i18n/i18n'
import useDevice from 'hooks/useDevice'
import styles from './ModalBase.module.scss'

type Props = {
  size: 'small' | 'large'
  onClose: () => void
  children?: ReactNode
  headerContent?: ReactNode
  footerContent?: ReactNode
}

const t = getT('global')

const ModalBase = ({size, onClose, headerContent, children}: Props) => {
  const device = useDevice()
  const overlayRef = useRef<HTMLDivElement>(null)
  const windowRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  useOnElementClick(overlayRef, onClose)
  useOnKeyPress('Escape', onClose)

  return (
    <div className={styles.overlay} ref={overlayRef}>
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: false,
          initialFocus: closeButtonRef.current || false
        }}>
        {/* TODO: should have aria title and description? */}
        <section
          className={c(styles.window, styles[size])}
          role='dialog'
          aria-modal='true'
          ref={windowRef}>
          <header className={styles.header}>
            <div className={styles.headerContentContainer}>{headerContent}</div>
            <IconButton
              type='close'
              onClick={onClose}
              size={device === 'mouse' ? 's' : 'm'}
              ariaLabel={t('close')}
              hasHoverIndicator
              ref={closeButtonRef}
            />
          </header>
          <div className={styles.contentContainer}>{children}</div>
        </section>
      </FocusTrap>
    </div>
  )
}

export default ModalBase
