'use client'

const GlobalLoadingIndicator = () => {
  // TODO: update without react-query
  // const isFetching = useIsFetching()

  // return <>{isFetching ? <LoadingIndicator size='l' /> : null}</>
  // TEMP:
  return null
}

export default GlobalLoadingIndicator
