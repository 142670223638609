'use client'

import React, {ReactNode, createContext, useContext, useMemo, useState} from 'react'

type Context = {
  isSoundEnabled: boolean
  toggleSound: () => void
}

const SoundSettingsContext = createContext<Context | undefined>(undefined)

type ProviderProps = {
  children: ReactNode
}

export const SoundSettingsProvider = ({children}: ProviderProps) => {
  const [isSoundEnabled, setIsSoundEnabled] = useState(true)

  const value = useMemo<Context>(
    () => ({
      isSoundEnabled,
      toggleSound: () => setIsSoundEnabled((cur) => !cur)
    }),
    [isSoundEnabled]
  )

  return <SoundSettingsContext.Provider value={value}>{children}</SoundSettingsContext.Provider>
}

export const useSoundSettings = () => {
  const context = useContext(SoundSettingsContext)
  if (context === undefined) {
    throw new Error('useSoundSettings must be used within a SoundSettingsProvider')
  }

  return context
}
