'use client'

import {useSelectedLayoutSegments} from 'next/navigation'
import {ReactNode, createContext, useContext} from 'react'

const ActivePathContext = createContext<string | undefined>(undefined)

type ProviderProps = {
  children: ReactNode
}

// When using a modal as a parallel route (@modal), active path points to the background behind the modal.
// pathname: modal page itself
// active path: page in the background

export const ActivePathProvider = ({children}: ProviderProps) => {
  const segments = useSelectedLayoutSegments()
  // Skips route groups and default segment:
  const path =
    '/' +
    segments.filter((segment) => segment.charAt(0) !== '(' && segment !== '__DEFAULT__').join('/')

  return <ActivePathContext.Provider value={path}>{children}</ActivePathContext.Provider>
}

export const useActivePath = () => {
  const context = useContext(ActivePathContext)
  if (context === undefined) {
    throw new Error('useActivePath must be used within an ActivePathProvider')
  }

  return context
}
