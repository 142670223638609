'use client'

import Image from 'next/image'
import Link from 'next/link'
import {usePathname} from 'next/navigation'
import {ReactElement} from 'react'
import Scanner from 'public/scanner.png'
import Orders from 'public/icons/bottom-bar/orders.svg'
import Customers from 'public/icons/bottom-bar/customers.svg'
import Bags from 'public/icons/bottom-bar/bags.svg'
import Activity from 'public/icons/bottom-bar/activity.svg'
import {c} from 'utils/className'
import {getT} from 'i18n/i18n'
import styles from './BottomBar.module.scss'

type ItemProps = {
  href: string
  label: string
  icon: ReactElement
}

const t = getT('nav')

const Item = ({href, label, icon}: ItemProps) => {
  const pathname = usePathname()
  const isActive = pathname === href

  return (
    <Link className={c(styles.link, isActive && styles.active)} href={href}>
      {icon}
      {label}
    </Link>
  )
}

// TODO: add alt to icons?
const ordersIcon = <Orders className={styles.icon} />
const customersIcon = <Customers className={styles.icon} />
const bagsIcon = <Bags className={styles.icon} />
const activityIcon = <Activity className={styles.icon} />

const BottomBar = () => {
  return (
    <div className={styles.container}>
      <Item href='/orders' label={t('orders')} icon={ordersIcon} />
      <Item href='/customers' label={t('customers')} icon={customersIcon} />
      <Link className={styles.link} href='/scanner'>
        <Image className={styles.QRIcon} src={Scanner} alt={t('scanner_icon_alt')} />
      </Link>
      <Item href='/bags' label={t('bags')} icon={bagsIcon} />
      <Item href='/activity' label={t('activity')} icon={activityIcon} />
    </div>
  )
}

export default BottomBar
