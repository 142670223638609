import {ReactNode} from 'react'
import {c} from 'utils/className'
import styles from './ButtonGroup.module.scss'

type Props = {
  type?: 'row' | 'column'
  children: ReactNode
}

const ButtonGroup = ({type = 'row', children}: Props) => {
  return (
    <div className={c(styles.container, type === 'row' ? styles.row : styles.column)}>
      {children}
    </div>
  )
}

export default ButtonGroup
