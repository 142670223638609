import {ComponentProps, ReactNode} from 'react'
import ButtonGroup from 'components/ButtonGroup/ButtonGroup'
import ActionButton from 'components/buttons/ActionButton'
import ModalBase from '../ModalBase/ModalBase'
import styles from './Dialog.module.scss'

type ButtonProps = ComponentProps<typeof ActionButton>

type Props = {
  title: string
  onClose: () => void
  buttons?: ButtonProps[]
  children: ReactNode
}

const Dialog = ({title, onClose, buttons, children}: Props) => {
  const footerContent = buttons ? (
    <ButtonGroup>
      {buttons.map((button) => (
        <ActionButton
          {...button}
          onClick={() => {
            onClose()
            void button.onClick?.()
          }}
          key={button.label}
        />
      ))}
    </ButtonGroup>
  ) : null

  return (
    <ModalBase size='small' onClose={onClose} headerContent={<h3>{title}</h3>}>
      <div className={styles.contentContainer}>
        <div className={styles.bodyContainer}>{children}</div>
        {footerContent && <footer>{footerContent}</footer>}
      </div>
    </ModalBase>
  )
}

export default Dialog
