import LoadingIndicator from 'components/loading/LoadingIndicator/LoadingIndicator'
import {c} from 'utils/className'
import styles from './button.module.scss'

type Props = {
  label: string
  onClick?: () => void
  type?: 'primary' | 'secondary' | 'destructive'
  isSmall?: boolean
  isDisabled?: boolean
  isLoading?: boolean
}

const ActionButton = ({
  label,
  type = 'primary',
  isSmall,
  isDisabled,
  isLoading,
  onClick
}: Props) => {
  const className = c(
    styles.container,
    styles[type],
    isDisabled && styles.disabled,
    isLoading && styles.loading,
    isSmall && styles.small
  )

  return (
    <button
      className={className}
      type={'button'}
      onClick={() => onClick?.()} // TODO: add ts / eslint rule for this: onClick should not be passed a clickevent.
      disabled={isDisabled || isLoading}>
      <span className={c(isLoading && styles.hidden)}>{label}</span>
      {isLoading && (
        <div className={styles.loadingIndicatorContainer}>
          <LoadingIndicator size='m' />
        </div>
      )}
    </button>
  )
}

export default ActionButton
