import {useEffect, useState} from 'react'

const useDevice = () => {
  const [device, setDevice] = useState<'mouse' | 'touch'>()

  useEffect(() => {
    setDevice(window.matchMedia('(hover: none)').matches ? 'touch' : 'mouse')
  }, [])

  return device
}

export default useDevice
