import {RefObject, useEffect} from 'react'

const useOnKeyPress = (
  key: string | RegExp,
  fn: (key: string) => void,
  targetRef?: RefObject<HTMLElement>
) => {
  useEffect(() => {
    const regex = typeof key === 'string' ? new RegExp(key) : key

    const hasTargetRef = Boolean(targetRef)
    const isTargetInitialized = Boolean(targetRef?.current)
    if (hasTargetRef && !isTargetInitialized) {
      return
    }

    const target = targetRef ? targetRef?.current : document

    const handleKeyPress = (e: KeyboardEvent) => {
      const isKeyCombination = e.metaKey || e.altKey || e.ctrlKey
      if (isKeyCombination) {
        return
      }

      if (regex.test(e.key)) {
        e.preventDefault()
        e.stopPropagation()
        fn(e.key)
      }
    }
    // @ts-ignore: event type is incorrect due to union of HTMLElement | Document:
    target.addEventListener('keydown', handleKeyPress)

    return () => {
      // @ts-ignore: event type is incorrect due to union of HTMLElement | Document:
      target.removeEventListener('keydown', handleKeyPress)
    }
  }, [fn, key, targetRef])
}

export default useOnKeyPress
