import {ComponentProps, ForwardedRef, forwardRef} from 'react'
import Link from 'next/link'
import Icon from 'components/Icon/Icon'
import {c} from 'utils/className'
import styles from './IconButton.module.scss'

interface BaseProps extends Pick<ComponentProps<typeof Icon>, 'type' | 'color' | 'size'> {
  hasHoverIndicator?: boolean
  ariaLabel: string
  isDisabled?: boolean
}

type ActionButtonProps = {onClick: () => void; href?: undefined}
type LinkButtonProps = {onClick?: undefined; href: string}
type Props = BaseProps & (ActionButtonProps | LinkButtonProps)

const IconButton = (
  {onClick, href, hasHoverIndicator, size, ariaLabel, isDisabled, ...rest}: Props,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const className = c(styles.button, styles[size], hasHoverIndicator && styles.hasHoverIndicator)
  const icon = <Icon size={size} {...rest} />

  // <Link> cannot be disabled, therefore render disabled <button> when isDisabled:
  if (onClick || isDisabled) {
    return (
      <button
        type='button'
        className={className}
        onClick={onClick}
        aria-label={ariaLabel}
        title={ariaLabel}
        disabled={isDisabled}
        ref={ref}>
        {icon}
      </button>
    )
  }

  return (
    <Link className={className} href={href} aria-label={ariaLabel} title={ariaLabel}>
      {icon}
    </Link>
  )
}

export default forwardRef(IconButton)
