import {ComponentProps} from 'react'
import Icon from 'components/Icon/Icon'
import styles from './LoadingIndicator.module.scss'

type Props = Pick<ComponentProps<typeof Icon>, 'size'>

const LoadingIndicator = ({size}: Props) => {
  return (
    <div className={styles.container}>
      <Icon type='loading' size={size} />
    </div>
  )
}

export default LoadingIndicator
