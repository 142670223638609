'use client'

import {ReactNode, useRef} from 'react'
import {ViewportRefProvider} from 'contexts/ViewportRefContext'
import BottomBar from 'components/BottomBar/BottomBar'
import useDisableScroll from 'hooks/useDisableScroll'
import GlobalLoadingIndicator from 'components/loading/GlobalLoadingIndicator/GlobalLoadingIndicator'
import styles from './TouchLayout.module.scss'

type Props = {
  children: ReactNode
}

const TouchLayout = ({children}: Props) => {
  // Prevents elastic scroll effect on iOS Safari:
  useDisableScroll()

  const viewportRef = useRef<HTMLDivElement>(null)

  return (
    <ViewportRefProvider viewportRef={viewportRef}>
      <div className={styles.container}>
        <main className={styles.pageContainer} ref={viewportRef}>
          {children}
          <div className={styles.loadingIndicatorContainer}>
            <GlobalLoadingIndicator />
          </div>
        </main>
        <BottomBar />
      </div>
    </ViewportRefProvider>
  )
}

export default TouchLayout
