'use client'

import React, {ComponentProps, ReactNode, createContext, useContext, useMemo, useState} from 'react'
import ActionButton from 'components/buttons/ActionButton'

const INITIAL_TITLE = ''
const INITIAL_BODY = ''
const INITIAL_BUTTONS: ButtonProps[] = []

type ButtonProps = ComponentProps<typeof ActionButton>

type NewType = ReactNode

type Context = {
  isVisible: boolean
  title: string
  body?: ReactNode
  buttons?: ButtonProps[]
  open: (title: string, body: NewType, buttons?: ButtonProps[]) => void
  close: () => void
}

const DialogContext = createContext<Context | undefined>(undefined)

type ProviderProps = {
  children: ReactNode
}

export const DialogProvider = ({children}: ProviderProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const [title, setTitle] = useState(INITIAL_TITLE)
  const [body, setBody] = useState<ReactNode>(INITIAL_BODY)
  const [buttons, setButtons] = useState<ButtonProps[] | undefined>(INITIAL_BUTTONS)

  const open = (title: string, body: ReactNode, buttons?: ButtonProps[]) => {
    setTitle(title)
    setBody(body)
    setButtons(buttons)
    setIsVisible(true)
  }

  const close = () => {
    setIsVisible(false)
    setTitle(INITIAL_TITLE)
    setBody(INITIAL_BODY)
    setButtons(INITIAL_BUTTONS)
  }

  const value = useMemo<Context>(
    () => ({
      isVisible,
      title,
      body,
      buttons,
      open,
      close
    }),
    [body, buttons, isVisible, title]
  )

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
}

export const useDialog = () => {
  const context = useContext(DialogContext)
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider')
  }

  return context
}
